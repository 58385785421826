import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import devHelper from 'dayjs/plugin/devHelper';
import duration from 'dayjs/plugin/duration';
import type { Duration } from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import toObject from 'dayjs/plugin/toObject';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

import { isDev } from './const';
import { leadingZero } from './utils';

dayjs.locale('ru');
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(dayOfYear);
dayjs.extend(isoWeek);
dayjs.extend(weekday);
dayjs.extend(duration);
dayjs.extend(toObject);
dayjs.extend(utc);

if (isDev) {
    dayjs.extend(devHelper);
}

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT2 = 'DD-MM-YYYY';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const UI_DATE_FORMAT = 'DD.MM.YYYY';
export const UI_DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const UI_DATETIME_FORMAT_SHORT = 'DD.MM.YYYY';
export const FILTER_DATE_FORMAT = 'DD.MM.YYYY';
export const PICKER_DATE_FORMAT = 'dd.MM.yyyy';
export const DATETIME_SERVER_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DURATION_FORMAT = 'HH:mm:ss';

export enum DateTimeType {
    DATE_TIME = 'datetime',
    DATE = 'date',
    TIME = 'time'
}

export const DataTimeArray = Object.values(DateTimeType);

export enum TimeFormat {
    TWENTY_FOUR = '24',
    TWELVE = '12'
}

export const TimeFormatArray = Object.values(TimeFormat).sort();

export enum ChartGrouping {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
    QUARTER = 'QUARTER'
}

export const CHART_GROUPING_LABEL = {
    [ChartGrouping.DAY]: 'День',
    [ChartGrouping.WEEK]: 'Неделя',
    [ChartGrouping.MONTH]: 'Месяц',
    [ChartGrouping.QUARTER]: 'Квартал',
    [ChartGrouping.YEAR]: 'Год'
};

export enum DateRange {
    ALL_TIME = 'ALL_TIME',
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH'
}

export const DATE_RANGE_LABEL = {
    [DateRange.ALL_TIME]: 'За все время',
    [DateRange.TODAY]: 'За сегодня',
    [DateRange.YESTERDAY]: 'За вчера',
    [DateRange.WEEK]: 'За неделю',
    [DateRange.MONTH]: 'За месяц'
};

export const PERIOD_OPTIONS = Object.entries(DATE_RANGE_LABEL).map(([value, label]) => ({
    label,
    value
}));

const range = (num: number): Array<string> => {
    const res = [];

    for (let i = 0; i <= num; i++) {
        res.push(leadingZero(i));
    }

    return res;
};

export const hours24Options = range(23);
export const hours12Options = range(12);
export const minutesOptions = range(59);
export const hours100Options = range(99);
export const secondsOptions = minutesOptions;

export const dateFormat2IMask = (format: string): string =>
    format
        .replace(/(dd|mm)/gi, '99')
        .replace(/yyyy/gi, '9999')
        .replace(/\./gi, '{.}');

export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
];

export const dateFnsFormat2Dayjs = (format: string): string => format.replace(/yyyy/g, 'YYYY').replace(/dd/g, 'DD');

export const currentYear = dayjs().format('YYYY');

export const today = dayjs().format(DATE_FORMAT);

export const partsToSeconds = (hours: number, minutes: number, seconds: number): number =>
    3600 * Number(hours) + 60 * Number(minutes) + Number(seconds);

export const createDuration = (hours: number, minutes: number, seconds: number): Duration =>
    dayjs.duration(partsToSeconds(hours, minutes, seconds), 'seconds');
