import cn from 'classnames';
import { useCallback, useMemo } from 'react';

import { history } from '@webapp/account/src/resources/history';
import { AccountRoutes } from '@webapp/account/src/resources/routes';
import { formatAmount } from '@webapp/common/lib/ui';
import { TestCheckStatus } from '@webapp/common/resources/survey';
import { DoubleCross, DoubleMark, RequiredMark } from '@webapp/ui/lib/icons';

import { Button } from '../button';
import { CssPageBlock } from '../survey-custom';

import css from './misc.css';

export const NoData: FC<{
    className?: string;
}> = ({ className = '' }) => <div className={className}>Нет данных</div>;

export const NoAccess: FC = () => <div className='content'>Доступ к разделу закрыт</div>;

export const TotalAnswers: FC<{
    totalCount: number;
}> = ({ totalCount }) => <div className={css.answer}>{`Всего ответов: ${formatAmount(totalCount)}`}</div>;

export const TotalCantRate: FC<{
    totalCantRate: number;
}> = ({ totalCantRate }) => <h4>{`Не могу оценить: ${formatAmount(totalCantRate)}`}</h4>;

export const SelfCount: FC<{
    count: number;
}> = ({ count }) => <h3>Свободные ответы ({count})</h3>;

export const ProPlusMark: FC = () => {
    const onClick = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        history.push(AccountRoutes.ordersLicence.link);
    }, []);
    return (
        <span className={css.proPlus} onClick={onClick}>
            &nbsp;(PRO+)
        </span>
    );
};

export const QuestionOrder: FC<{
    order: number;
    invalid?: boolean;
    style?: CSSProperties;
}> = ({ invalid, order, style }) => (
    <div className={cn(CssPageBlock.QUESTION_ORDER, cn(css.order, invalid && css.invalid))} style={style}>
        {order}.
    </div>
);

export const AnswerIcon: FC<{
    checked: boolean;
    testCheckStatus: TestCheckStatus;
}> = ({ checked, testCheckStatus }) => (
    <div className={css.answerIcon}>
        {testCheckStatus === TestCheckStatus.VALID ? (
            <DoubleMark className={css.valid} />
        ) : checked ? (
            <DoubleCross className={css.invalid} />
        ) : (
            <div />
        )}
    </div>
);

export const RestartButton: FC = () => {
    const onClick = useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <Button bordered slim onClick={onClick}>
            Новый ответ
        </Button>
    );
};

export const Required: FC<{
    className?: string;
    color?: string;
}> = ({ className, color }) => {
    const style = useMemo(() => ({ color }), [color]);
    return (
        <span className={cn(css.required, className)} style={style}>
            <RequiredMark />
        </span>
    );
};
